body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--surface-ground);
  text-align: center;
}

.App {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
}

.success-text {
  color: #86efac;
}

.failure-text {
  color: #ff5757;
}

.answer-box-container {
  margin-bottom: 1em;
}

p {
  font-size: x-large;
}
